// src/components/helmet.js
import React from "react";
import { Helmet } from "react-helmet";

const helmet = () => (
  <Helmet>
    <title>Convogenius | Branding and Sales Applications using AI</title>
    <meta
      name="description"
      content="Convogenius offers advanced branding and sales applications powered by AI. Enhance your business strategies and streamline your sales processes with cutting-edge technology."
    />
    <meta
      name="keywords"
      content="branding, sales applications, AI, artificial intelligence, business solutions, technology"
    />
    <meta name="author" content="Convogenius Team" />
    <meta
      property="og:title"
      content="Convogenius | Branding and Sales Applications using AI"
    />
    <meta
      property="og:description"
      content="Convogenius provides innovative branding and sales solutions powered by AI to help businesses thrive in the digital age."
    />
    <meta
      property="og:image"
      content="https://www.convogenius.my.id/path-to-your-image.jpg"
    />
    <meta property="og:url" content="https://www.convogenius.my.id" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Convogenius" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:title"
      content="Convogenius | Branding and Sales Applications using AI"
    />
    <meta
      name="twitter:description"
      content="Convogenius offers advanced branding and sales applications powered by AI. Enhance your business strategies and streamline your sales processes with cutting-edge technology."
    />
    <meta
      name="twitter:image"
      content="https://www.convogenius.my.id/path-to-your-image.jpg"
    />
    <link rel="canonical" href="https://www.convogenius.my.id" />
    {/* Google Tag Manager */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-LR5JNC7FGT"
    ></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-LR5JNC7FGT');
      `}
    </script>
  </Helmet>
);

export default helmet;
