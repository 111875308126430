import React, { useState } from "react";

const PrivacySettingPage = () => {
  const [profileVisibility, setProfileVisibility] = useState("Public");
  const [dataSharing, setDataSharing] = useState(false);
  const [notifications, setNotifications] = useState(true);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 text-gray-800">
          Privacy Settings
        </h1>

        {/* Profile Visibility Section */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Profile Visibility
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Control who can see your profile information.
          </p>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setProfileVisibility("Public")}
              className={`py-2 px-4 rounded-lg font-medium text-sm transition-colors ${
                profileVisibility === "Public"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Public
            </button>
            <button
              onClick={() => setProfileVisibility("Private")}
              className={`py-2 px-4 rounded-lg font-medium text-sm transition-colors ${
                profileVisibility === "Private"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Private
            </button>
            <button
              onClick={() => setProfileVisibility("Friends")}
              className={`py-2 px-4 rounded-lg font-medium text-sm transition-colors ${
                profileVisibility === "Friends"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Friends Only
            </button>
          </div>
        </div>

        {/* Data Sharing Section */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Data Sharing
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Allow the app to share your data for personalization.
          </p>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={dataSharing}
              onChange={() => setDataSharing(!dataSharing)}
            />
            <span className="ml-2 text-sm text-gray-700">
              Enable data sharing
            </span>
          </label>
        </div>

        {/* Notifications Section */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Notifications
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Manage how you receive notifications from us.
          </p>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={notifications}
              onChange={() => setNotifications(!notifications)}
            />
            <span className="ml-2 text-sm text-gray-700">
              Receive email notifications
            </span>
          </label>
        </div>

        {/* Save Changes Button */}
        <div className="text-right">
          <button className="py-2 px-6 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacySettingPage;
