import React, { useState } from "react";

const ManageBlockedAccounts = () => {
  const [blockedAccounts, setBlockedAccounts] = useState([
    { id: 1, name: "John Doe", blockedDate: "2024-09-15" },
    { id: 2, name: "Jane Smith", blockedDate: "2024-08-12" },
    { id: 3, name: "Michael Brown", blockedDate: "2024-07-21" },
    // Add more blocked accounts here
  ]);

  const handleUnblock = (id) => {
    const updatedAccounts = blockedAccounts.filter(
      (account) => account.id !== id
    );
    setBlockedAccounts(updatedAccounts);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 text-gray-800">
          Manage Blocked Accounts
        </h1>
        <p className="text-gray-500 mb-6">
          Review and manage the accounts you have blocked. You can unblock any
          account to restore interactions.
        </p>

        {blockedAccounts.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {blockedAccounts.map((account) => (
              <li
                key={account.id}
                className="py-4 flex justify-between items-center"
              >
                <div>
                  <p className="text-lg font-medium text-gray-700">
                    {account.name}
                  </p>
                  <p className="text-sm text-gray-500">
                    Blocked on: {account.blockedDate}
                  </p>
                </div>
                <button
                  onClick={() => handleUnblock(account.id)}
                  className="py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
                >
                  Unblock
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center text-gray-500">
            No blocked accounts at the moment.
          </p>
        )}
      </div>
    </div>
  );
};

export default ManageBlockedAccounts;
