import React, { useState } from "react";

const VideoModal = ({ video, onClose }) => {
  const [isFullDescription, setIsFullDescription] = useState(false);

  const formatTextWithLineBreaks = (text) =>
    text ? text.replace(/\n/g, "<br />") : "";

  const truncateText = (text, wordLimit) => {
    const words = text ? text.split(" ") : [];
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-2xl relative max-h-[90vh] overflow-y-auto">
        <button
          className="absolute top-1 right-1 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          ✕
        </button>
        <iframe
          className="w-full h-64 rounded-lg"
          src={`https://www.youtube.com/embed/${video.id}`}
          title={video.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <h4 className="font-semibold text-xl mt-4">{video.title}</h4>
        <p className="mt-2 text-gray-600">
          {isFullDescription ? (
            <span
              dangerouslySetInnerHTML={{
                __html: formatTextWithLineBreaks(video.description),
              }}
            />
          ) : (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: formatTextWithLineBreaks(
                    truncateText(video.description, 50)
                  ),
                }}
              />
              <button
                className="text-blue-500 ml-2"
                onClick={() => setIsFullDescription(true)}
              >
                Read More
              </button>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default VideoModal;
