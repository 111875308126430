import React from "react";

const ActionButtons = ({ handleSaveClick }) => {
  return (
    <div className="flex justify-between">
      <button
        onClick={handleSaveClick}
        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
      >
        Save Changes
      </button>
    </div>
  );
};

export default ActionButtons;
