import React from "react";

const Bookkeeping = () => {
  return (
    <section className="bg-white p-6 mt-16 mb-10 text-gray-800 flex flex-col lg:flex-row items-center border-t border-gray-700 lg:items-center">
      <div className="w-full lg:w-1/2 mb-4 lg:mb-0 lg:mr-4 flex flex-col justify-center lg:border-b-0 lg:border-r lg:pr-4 border-b border-gray-700 pb-4 lg:pb-0">
        <h1 className="text-3xl font-bold mb-2">
          Check your Bookkeeping Comparison
        </h1>
        <p>
          Use this feature to keep an eye on your expenses and ensure you're
          staying within your budget. Compare your monthly expenses to see where
          you can save more.
        </p>
        <a href="/bookkeeping-comparison">
          <button className="bg-blue-600 text-white hover:bg-blue-700 mt-4 px-4 py-2 rounded-lg font-semibold flex items-center">
            <span className="mr-2">Check Now</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </button>
        </a>
      </div>
      <div className="w-full lg:w-1/2 mb-4 lg:mb-0 lg:ml-4 flex flex-col justify-center lg:pl-4 border-gray-700 pt-4 lg:pt-0">
        <h1 className="text-3xl font-bold mb-2">
          Make a book of your finances and goods
        </h1>
        <p>
          Keep track of your investments and their performance over time.
          Analyze trends and make informed decisions to grow your portfolio.
        </p>
        <a href="/make-bookkeeping">
          <button className="bg-blue-600 text-white hover:bg-blue-700 mt-4 px-4 py-2 rounded-lg font-semibold flex items-center">
            <span className="mr-2">Make It Now</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </button>
        </a>
      </div>
    </section>
  );
};

export default Bookkeeping;
