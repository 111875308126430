import React from "react";
import Modal from "react-modal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Ensure Modal is properly initialized
Modal.setAppElement("#root");

const ModalComponent = ({
  isOpen,
  closeModal,
  modalImage,
  modalTitle,
  modalDescription,
  loading, // Add loading prop
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    contentLabel="Data Update Notification"
    className="fixed inset-0 flex justify-center items-center p-4"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  >
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto my-8 transform transition-all duration-300 ease-in-out">
      <div className="flex justify-center mb-4">
        {loading ? (
          <Skeleton circle={true} height={160} width={160} />
        ) : (
          <img src={modalImage} alt="Notification" className="w-40 h-40" />
        )}
      </div>
      <h2 className="text-2xl font-bold mb-4 text-center">
        {loading ? <Skeleton width={200} /> : modalTitle}
      </h2>
      <p className="text-center">
        {loading ? <Skeleton count={3} /> : modalDescription}
      </p>
      <div className="flex justify-center mt-4">
        <button
          onClick={closeModal}
          className={`px-6 py-2 rounded-full transition-colors ${
            loading
              ? "bg-gray-400 text-gray-700"
              : "bg-blue-600 text-white hover:bg-blue-700"
          }`}
          disabled={loading} // Disable button when loading
        >
          {loading ? <Skeleton width={80} /> : "Close"}
        </button>
      </div>
    </div>
  </Modal>
);

export default ModalComponent;
