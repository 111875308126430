// SearchBar.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchBar = ({ searchInput, handleSearchChange }) => {
  return (
    <div className="relative mb-4 w-full sm:w-auto">
      <input
        type="text"
        className="border rounded py-2 px-10 text-gray-700 w-full sm:w-auto"
        placeholder="Search by title..."
        value={searchInput}
        onChange={handleSearchChange}
      />
      <FontAwesomeIcon
        icon={faSearch}
        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
      />
    </div>
  );
};

export default SearchBar;
