import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HomePage from "./pages/home/home";
import Navbar from "./components/all/navbar";
import FooterSection from "./components/all/footer";
import UserFooter from "./components/all/UserFooter";
import LoginPage from "./pages/login/login";
import RegisterPage from "./pages/register/register";
import DashboardPage from "./pages/dashboard/dashboard";
import AccountPage from "./pages/account/account";
import ProfileEdit from "./pages/account/profile-edit";
import ChtPage from "./pages/AI/cht-ai";
import ImagePage from "./pages/AI/image-ai";
import MusicPage from "./pages/AI/music-ai";
import RoomChatPage from "./pages/room_fitur/room_cht";
import PasswordReset from "./pages/password_reset/password-reset";
import MakeBookkeeping from "./pages/bookkeeping/make-bookkeeping";
import CreateBookkeeping from "./pages/bookkeeping/create-bookkeeping";
import BookkeepingComparison from "./pages/bookkeeping/bookkeeping-comparison";
import AboutPage from "./pages/home/about";
import ServicesPage from "./pages/home/services";
import ProfileCompanyPage from "./pages/home/profile-company";
import ContactPage from "./pages/home/contact";
import BlogPage from "./pages/blog/blog";
import DetailBlog from "./pages/blog/detail_blog";
import QuestionerPage from "./pages/questioner/questioner";
import SettingPage from "./pages/settings/settings";
import ChangePage from "./pages/settings/account/change-password";
import ManageAccountPage from "./pages/settings/account/manage-account";
import ChangeEmailPage from "./pages/settings/account/change-email";
import DeleteAccountPage from "./pages/settings/account/delete-account";
import PrivacySettingsPage from "./pages/settings/privacy-and-security/privacy-settings";
import SecuritySettingsPage from "./pages/settings/privacy-and-security/security-settings";
import DataPermissionsPage from "./pages/settings/privacy-and-security/data-permissions";
import ManageBlockedAccountPage from "./pages/settings/privacy-and-security/manage-blocked-accounts";
import HelpArticlesPage from "./pages/settings/help-center/help-articles";
import ContactSupportPage from "./pages/settings/help-center/contact-support";
import ReportProblem from "./pages/settings/help-center/report-problem";
import TermsOfServicePage from "./pages/settings/help-center/terms-service";
import AllTrendingYoutube from "./pages/trending/all-trending-youtube";
import NotFoundPage from "./pages/error_handler/NotFoundPage";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    // List of protected routes that require authentication
    const protectedRoutes = [
      "/dashboard",
      "/account",
      "/profile-edit",
      "/settings",
      "/cht-ai",
      "/image-ai",
      "/music-ai",
      "/room_cht",
      "/password-reset",
      "/change-password",
      "/manage-account",
      "/change-email",
      "/delete-account",
      "/bookkeeping-comparison",
      "/make-bookkeeping",
      "/create-bookkeeping",
      "/questioner",
      "/privacy-settings",
      "/security-settings",
      "/data-permissions",
      "/manage-blocked-accounts",
      "/help-articles",
      "/contact-support",
      "/report-problem",
      "/terms-service",
      "/all-trending-youtube",
    ];

    // Redirect to login if token is missing and the user is on a protected route
    if (
      !token &&
      protectedRoutes.some((route) => location.pathname.startsWith(route))
    ) {
      localStorage.removeItem("userEmail"); // Remove email if token is missing
      navigate("/login"); // Redirect to login
    }

    // Redirect to dashboard if user is logged in and is on the home page
    if (token && location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [location, navigate]);

  const isAuthPage =
    location.pathname === "/login" || location.pathname === "/register";
  const isDashboardPage = location.pathname === "/dashboard";
  const isSettingsPage = location.pathname === "/settings"; // Fixed comparison operator
  const isChtPage = location.pathname.startsWith("/cht-ai");
  const isImagePage = location.pathname === "/image-ai";
  const isMusicPage = location.pathname === "/music-ai";
  const isRoomChatPage = location.pathname === "/room_cht";
  const isAccountPage = location.pathname === "/account";
  const isProfileEditPage = location.pathname === "/profile-edit";
  const isPasswordReset = location.pathname === "/password-reset";
  const isChangePassword = location.pathname === "/change-password";
  const isManageAccount = location.pathname === "/manage-account";
  const isChangeEmail = location.pathname === "/change-email";
  const isDeleteAccount = location.pathname === "/delete-account";
  const isMakeBookkeeping = location.pathname === "/make-bookkeeping";
  const isQuestioner = location.pathname === "/questioner";
  const isPrivacySettingsPage = location.pathname === "/privacy-settings";
  const isSecuritySettingsPage = location.pathname === "/security-settings";
  const isDataPermissionsPage = location.pathname === "/data-permissions";
  const isManageBlockedAccount =
    location.pathname === "/manage-blocked-accounts";
  const isHelpArticlesPage = location.pathname === "/help-articles";
  const isContactSupportPage = location.pathname === "/contact-support";
  const isReportProblem = location.pathname === "/report-problem";
  const isTermsOfServicePage = location.pathname === "/terms-service";
  const isAllTrendingYoutube = location.pathname === "/all-trending-youtube";
  const isCreateBookkeeping = location.pathname === "/create-bookkeeping";
  const isBookkeepingComparison =
    location.pathname === "/bookkeeping-comparison";

  const isNotFoundPage = ![
    "/",
    "/login",
    "/register",
    "/dashboard",
    "/account",
    "/profile-edit",
    "/cht-ai",
    "/image-ai",
    "/music-ai",
    "/room_cht",
    "/password-reset",
    "/change-password",
    "/delete-account",
    "/bookkeeping-comparison",
    "/create-bookkeeping",
    "/blog",
    "/about",
    "/services",
    "/profile-company",
    "/contact",
    "/questioner",
    "/settings",
    "/manage-account",
    "/change-email",
    "/detail_blog",
    "/make-bookkeeping",
    "/privacy-settings",
    "/security-settings",
    "/data-permissions",
    "/manage-blocked-accounts",
    "/help-articles",
    "/contact-support",
    "/report-problem",
    "/terms-service",
    "/all-trending-youtube",
  ].some((route) => location.pathname.startsWith(route));

  return (
    <div>
      {!isAuthPage && <Navbar />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/change-password" element={<ChangePage />} />
        <Route path="/settings" element={<SettingPage />} />
        <Route path="/manage-account" element={<ManageAccountPage />} />
        <Route path="/change-email" element={<ChangeEmailPage />} />
        <Route path="/delete-account" element={<DeleteAccountPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/profile-edit" element={<ProfileEdit />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/profile-company" element={<ProfileCompanyPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/questioner" element={<QuestionerPage />} />
        <Route path="/privacy-settings" element={<PrivacySettingsPage />} />
        <Route path="/security-settings" element={<SecuritySettingsPage />} />
        <Route path="/data-permissions" element={<DataPermissionsPage />} />
        <Route
          path="/manage-blocked-accounts"
          element={<ManageBlockedAccountPage />}
        />
        <Route path="/help-articles" element={<HelpArticlesPage />} />
        <Route path="/contact-support" element={<ContactSupportPage />} />
        <Route path="/report-problem" element={<ReportProblem />} />
        <Route path="/terms-service" element={<TermsOfServicePage />} />
        <Route path="/detail_blog/:slug" element={<DetailBlog />} />
        <Route path="/cht-ai" element={<ChtPage />} />
        <Route path="/cht-ai/:chatId" element={<ChtPage />} />
        <Route
          path="/bookkeeping-comparison"
          element={<BookkeepingComparison />}
        />
        <Route path="/make-bookkeeping" element={<MakeBookkeeping />} />
        <Route
          path="/create-bookkeeping/:monthYearId"
          element={<CreateBookkeeping />}
        />
        <Route path="/image-ai" element={<ImagePage />} />
        <Route path="/music-ai" element={<MusicPage />} />
        <Route path="/room_cht" element={<RoomChatPage />} />
        <Route path="/all-trending-youtube" element={<AllTrendingYoutube />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {!isAuthPage &&
        !isNotFoundPage &&
        !isChangePassword &&
        !isDashboardPage &&
        !isSettingsPage &&
        !isManageAccount &&
        !isChangeEmail &&
        !isDeleteAccount &&
        !isPrivacySettingsPage &&
        !isSecuritySettingsPage &&
        !isDataPermissionsPage &&
        !isManageBlockedAccount &&
        !isHelpArticlesPage &&
        !isContactSupportPage &&
        !isReportProblem &&
        !isTermsOfServicePage &&
        !isChtPage &&
        !isImagePage &&
        !isMusicPage &&
        !isRoomChatPage &&
        !isQuestioner &&
        !isAccountPage &&
        !isProfileEditPage &&
        !isPasswordReset &&
        !isAllTrendingYoutube &&
        !isBookkeepingComparison &&
        !isCreateBookkeeping &&
        !isMakeBookkeeping && <FooterSection />}
      {(isDashboardPage ||
        isAccountPage ||
        isChtPage ||
        isImagePage ||
        isMusicPage ||
        isRoomChatPage ||
        isMakeBookkeeping ||
        isCreateBookkeeping ||
        isBookkeepingComparison) && <UserFooter />}
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
