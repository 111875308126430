import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("userEmail");

  const handleGoBack = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundColor: "white",
      color: "#1F2937", // Tailwind gray-800
      padding: "16px", // Add padding for smaller screens
      textAlign: "center", // Center align text for mobile
    },
    bounce: {
      fontSize: "4rem", // Adjust font size for mobile
      fontWeight: "800",
      color: "#2563EB", // Tailwind blue-600
      animation: "bounce 1.5s infinite",
      margin: "0 8px", // Prevent text overflow
    },
    wave: {
      fontSize: "1.25rem", // Slightly smaller for mobile
      marginTop: "8px",
      animation: "wave-animation 2s infinite ease-in-out",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#2563EB",
      color: "white",
      borderRadius: "8px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      marginTop: "16px",
      width: "100%", // Full width button on mobile
      maxWidth: "300px", // Restrict max width for large screens
    },
    buttonHover: {
      backgroundColor: "#1E40AF", // Tailwind blue-700
    },
  };

  return (
    <div style={styles.container}>
      <div className="animation-container">
        <h1 style={styles.bounce}>404</h1>
        <div style={styles.wave}>Page Not Found</div>
      </div>
      <p className="text-xl mt-4 mb-8">
        Oops! The page you're looking for doesn't exist.
      </p>
      <button
        onClick={handleGoBack}
        style={styles.button}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            styles.buttonHover.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor =
            styles.button.backgroundColor)
        }
      >
        Go back to {isLoggedIn ? "Dashboard" : "Home"}
      </button>

      {/* CSS-in-JS animations */}
      <style jsx>{`
        @keyframes wave-animation {
          0%,
          100% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(10px);
          }
        }

        @keyframes bounce {
          0%,
          100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-15px);
          }
        }

        @media (max-width: 640px) {
          /* Mobile adjustments */
          h1 {
            font-size: 3rem;
          }

          .wave {
            font-size: 1rem;
          }

          p {
            font-size: 1rem;
            margin-top: 12px;
          }
        }
      `}</style>
    </div>
  );
};

export default NotFoundPage;
