// Pagination.js
import React from "react";

const Pagination = ({ currentPage, totalPages, handleClick }) => {
  const pageNumbers = [];

  if (currentPage > 1) {
    pageNumbers.push(currentPage - 1);
  }

  pageNumbers.push(currentPage);

  if (currentPage < totalPages) {
    pageNumbers.push(currentPage + 1);
  }

  return (
    <div className="flex justify-center mt-4">
      <ul className="flex items-center space-x-2">
        {pageNumbers.map((pageNumber) => (
          <li key={pageNumber}>
            <button
              onClick={() => handleClick(pageNumber)}
              className={`${
                currentPage === pageNumber
                  ? "bg-blue-600 text-white"
                  : "bg-white text-blue-600 hover:bg-blue-600 hover:text-white"
              } transition-colors px-4 py-2 rounded-full shadow`}
            >
              {pageNumber}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;
