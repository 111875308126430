import React from "react";

const TrendingCard = ({ video, onClick }) => (
  <div
    className="text-gray-800 hover:text-white bg-white border border-blue-600 p-4 rounded-lg shadow-lg flex flex-col items-center min-w-[16rem] transition duration-300 hover:bg-blue-800"
    onClick={onClick}
  >
    <img
      src={video.thumbnail_url}
      alt={video.title}
      className="w-full h-40 object-cover rounded-lg mb-4"
    />
    <h4 className="font-semibold mb-2 lg:text-sm text-sm text-center">
      {video.title}
    </h4>
  </div>
);

export default TrendingCard;
