import React from "react";

const UserFooter = () => {
  return (
    <footer className="bg-gray-white text-gray-800 border-t border-b border-gray-600 py-3 mt-5">
      <div className="container mx-auto text-center">
        <p className="text-sm">© 2024 Convogenius. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default UserFooter;
