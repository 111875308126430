import React from "react";
import { Helmet } from "react-helmet"; // Import React Helmet

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>
          About Convogenius | Branding and Sales Applications using AI
        </title>
        <meta
          name="description"
          content="Learn more about Convogenius, a leader in AI-driven branding and sales applications that enhance product visibility and engagement."
        />
        <meta
          name="keywords"
          content="Convogenius, AI, branding, sales applications, artificial intelligence, business solutions"
        />
        <meta name="author" content="Convogenius Team" />
        <meta property="og:title" content="About Convogenius" />
        <meta
          property="og:description"
          content="Convogenius specializes in AI solutions to enhance branding and sales strategies."
        />
        <meta
          property="og:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <meta property="og:url" content="https://www.convogenius.my.id/about" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Convogenius" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Convogenius" />
        <meta
          name="twitter:description"
          content="Learn more about Convogenius and our innovative AI solutions."
        />
        <meta
          name="twitter:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <link rel="canonical" href="https://www.convogenius.my.id/about" />
      </Helmet>

      <div className="min-h-screen bg-gray-100 p-6 flex flex-col items-center">
        <h1 className="text-4xl font-semibold mb-6 text-gray-800">
          About Convogenius
        </h1>
        <p className="text-lg text-gray-600 mb-8 text-center max-w-2xl">
          Convogenius is a company specializing in artificial intelligence,
          dedicated to helping brands enhance their product visibility and
          engagement through innovative AI solutions.
        </p>

        {/* Section 1: Company Overview */}
        <div className="flex flex-col md:flex-row max-w-6xl bg-white p-6 rounded-lg shadow-lg mb-8">
          <div className="md:w-1/2 p-4">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">
              What is Convogenius?
            </h2>
            <p className="text-lg text-gray-600">
              Our cutting-edge tools utilize AI chat, descriptive content,
              images, and music to create a unique branding experience for
              businesses of all sizes.
            </p>
            <a href="/profile-company">
              <button className="mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300">
                Learn More
              </button>
            </a>
          </div>
          <div className="md:w-1/2 p-4">
            <img
              src="images/about/company-profile.webp"
              alt="Convogenius Overview"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Section 2: AI Features */}
        <div className="flex flex-col md:flex-row max-w-6xl bg-white p-6 rounded-lg shadow-lg mb-8">
          <div className="md:w-1/2 p-4">
            <img
              src="images/about/feature.png"
              alt="AI Features"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div className="md:w-1/2 p-4">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">
              AI Features
            </h2>
            <p className="text-lg text-gray-600">
              Our AI features include:
              <ul className="list-disc list-inside mt-2">
                <li>AI Chat for interactive customer engagement</li>
                <li>Descriptive content generation for marketing</li>
                <li>Image processing for visual branding</li>
                <li>Music integration for enhanced user experience</li>
              </ul>
            </p>
          </div>
        </div>

        {/* Section 3: Additional Features */}
        <div className="flex flex-col md:flex-row max-w-6xl bg-white p-6 rounded-lg shadow-lg">
          <div className="md:w-1/2 p-4">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">
              Additional Features
            </h2>
            <p className="text-lg text-gray-600">
              We also offer additional features such as:
              <ul className="list-disc list-inside mt-2">
                <li>Accounting tools for financial management</li>
                <li>Sales charts and graphs for performance tracking</li>
                <li>Trending YouTube content to boost marketing efforts</li>
              </ul>
            </p>
          </div>
          <div className="md:w-1/2 p-4">
            <img
              src="images/about/addtional.png"
              alt="Additional Features"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
