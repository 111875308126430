import React, { useState } from "react";

const HelpArticlesPage = () => {
  const [articles] = useState([
    {
      id: 1,
      title: "How to Change Your Password",
      summary:
        "Learn the steps to update your account password for better security.",
      date: "2024-09-01",
      content:
        "To change your password, go to Settings > Security > Change Password. Enter your current password, then type your new password and confirm it.",
    },
    {
      id: 2,
      title: "Enabling Two-Factor Authentication",
      summary: "Secure your account by setting up two-factor authentication.",
      date: "2024-08-25",
      content:
        "To enable two-factor authentication, go to Settings > Security > Two-Factor Authentication. Follow the instructions to set up 2FA on your phone.",
    },
    {
      id: 3,
      title: "Managing Notifications",
      summary: "Find out how to customize your notification preferences.",
      date: "2024-08-15",
      content:
        "To manage notifications, go to Settings > Notifications. You can toggle different types of notifications on or off as per your preference.",
    },
  ]);

  const [selectedArticle, setSelectedArticle] = useState(null);

  const openModal = (article) => {
    setSelectedArticle(article);
  };

  const closeModal = () => {
    setSelectedArticle(null);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 lg:mb-0 mb-20">
      <div className="max-w-6xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 text-gray-800">
          Help Articles
        </h1>
        <p className="text-gray-500 mb-8">
          Explore the most frequently asked questions and help guides to resolve
          your issues.
        </p>

        {/* Articles List */}
        <div className="space-y-6">
          {articles.map((article) => (
            <div
              key={article.id}
              className="p-6 bg-gray-50 rounded-lg shadow-sm hover:shadow-lg transition duration-300"
            >
              <h2 className="text-xl font-medium text-gray-800 mb-2">
                {article.title}
              </h2>
              <p className="text-gray-600 mb-3">{article.summary}</p>
              <div className="flex justify-between items-center">
                <p className="text-sm text-gray-400">
                  Published on {article.date}
                </p>
                <button
                  className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
                  onClick={() => openModal(article)}
                >
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedArticle && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg relative mx-4 sm:mx-6">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              {selectedArticle.title}
            </h2>
            <p className="text-sm text-gray-400 mb-6">
              Published on {selectedArticle.date}
            </p>
            <p className="text-gray-700">{selectedArticle.content}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpArticlesPage;
