import React from "react";
import { Helmet } from "react-helmet";
import {
  FaTools,
  FaMusic,
  FaChartLine,
  FaFileInvoiceDollar,
  FaYoutube,
} from "react-icons/fa";

const services = [
  {
    title: "AI-Generated Product Descriptions",
    description:
      "Create persuasive product descriptions that resonate with your audience and elevate your brand image using AI technology.",
    icon: <FaTools className="text-6xl text-blue-600 mb-4" />,
  },
  {
    title: "Music Integration",
    description:
      "Integrate music into your branding strategy to build memorable experiences and stronger customer connections.",
    icon: <FaMusic className="text-6xl text-green-500 mb-4" />,
  },
  {
    title: "Sales Analysis Tools",
    description:
      "Use advanced analytics to track your sales performance and make data-driven decisions with ease.",
    icon: <FaChartLine className="text-6xl text-purple-500 mb-4" />,
  },
  {
    title: "Bookkeeping Assistance",
    description:
      "Simplify financial management and effortlessly keep track of your revenue and expenses with our bookkeeping tools.",
    icon: <FaFileInvoiceDollar className="text-6xl text-yellow-500 mb-4" />,
  },
  {
    title: "Trending YouTube Content",
    description:
      "Stay inspired with trending YouTube content that keeps your branding fresh and ahead of the curve.",
    icon: <FaYoutube className="text-6xl text-red-500 mb-4" />,
  },
];

const ServicesPage = () => {
  return (
    <>
      <Helmet>
        <title>Convogenius | Premium Business Services</title>
        <meta
          name="description"
          content="Explore premium services like AI-generated content, music integration, sales analytics, bookkeeping, and trending YouTube insights with Convogenius."
        />
        <meta
          name="keywords"
          content="AI product descriptions, music integration, sales tools, bookkeeping, YouTube trends, business solutions"
        />
        <meta name="author" content="Convogenius Team" />
        <meta
          property="og:title"
          content="Convogenius | Premium Business Services"
        />
        <meta
          property="og:description"
          content="Discover innovative services to grow your business and enhance your brand strategy with AI-powered solutions."
        />
        <meta
          property="og:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <meta
          property="og:url"
          content="https://www.convogenius.my.id/services"
        />
        <link rel="canonical" href="https://www.convogenius.my.id/services" />
      </Helmet>

      <main className="min-h-screen bg-white py-12 px-6">
        <nav className="text-sm mb-8">
          <a href="/" className="text-blue-600 hover:underline">
            Home
          </a>{" "}
          / <span className="text-gray-500">Our Services</span>
        </nav>

        <header className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-extrabold text-gray-800 mb-6">
            Our Premium Services
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            We offer innovative business solutions to enhance your brand and
            streamline operations, helping you achieve sustainable growth.
          </p>
        </header>

        {/* Services Grid */}
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 max-w-7xl mx-auto">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-gray-50 border border-gray-200 rounded-lg p-8 text-center shadow-sm"
              aria-labelledby={`service-title-${index}`}
            >
              {service.icon}
              <h2
                id={`service-title-${index}`}
                className="text-2xl font-semibold text-gray-800 mb-4"
              >
                {service.title}
              </h2>
              <p className="text-gray-600 leading-relaxed">
                {service.description}
              </p>
            </div>
          ))}
        </section>

        <div className="mt-16 text-center">
          <a href="/contact">
            <button className="bg-blue-600 text-white py-3 px-8 rounded-full shadow-md hover:bg-blue-700 transition duration-300">
              Contact Us
            </button>
          </a>
        </div>
      </main>
    </>
  );
};

export default ServicesPage;
