import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EnvelopeIcon,
  UserIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { ClipLoader } from "react-spinners";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value && !value.includes("@")) {
      setEmailError("Email must include '@'.");
    } else {
      setEmailError("");
    }
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    if (!value) {
      setUsernameError("Username is required.");
    } else {
      setUsernameError("");
    }
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (value && !regex.test(value)) {
      setPasswordError("Password must contain letters and numbers.");
    } else {
      setPasswordError("");
    }

    if (confirmPassword && value !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (newPassword && value !== newPassword) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      if (!email) {
        toast.error("Please enter your email.");
        return;
      }
      if (emailError) {
        toast.error("Please correct the email error.");
        return;
      }
    }
    if (currentStep === 2) {
      if (!username) {
        setUsernameError("Username is required.");
        toast.error("Please enter your username.");
        return;
      }
      if (usernameError) {
        toast.error("Please correct the username error.");
        return;
      }
    }
    if (currentStep === 3) {
      if (!oldPassword) {
        toast.error("Please enter your old password.");
        return;
      }
      if (!newPassword) {
        toast.error("Please enter your new password.");
        return;
      }
      if (passwordError || confirmPasswordError) {
        toast.error("Please correct the password errors.");
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (emailError || passwordError || confirmPasswordError) {
      toast.error("Please correct any errors in the form.");
      return;
    }

    setLoading(true);

    const requestData = {
      email,
      username,
      old_password: oldPassword,
      new_password: newPassword,
    };

    fetch(
      "https://convogenius-backend-production.up.railway.app/api/v1/reset-password",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    )
      .then((response) => {
        setLoading(false);
        if (response.status === 400) {
          throw new Error(
            "Account has not been registered, please register first"
          );
        }
        if (response.status === 401) {
          throw new Error("Old password does not match.");
        }
        if (!response.ok) {
          throw new Error(
            "Reset Password failed. Try using another email and username."
          );
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        setResetPasswordSuccess(true);
        toast.success("Password reset successful!");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000); // Redirect to login page after 3 seconds
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(
          error.message ||
            "Reset Password failed. Your email is already in use."
        );
      });
  };

  const handleKeyDown = (e, step) => {
    if (step < 3) {
      if (e.key === "Enter") {
        handleNextStep();
      }
    } else if (step === 3) {
      if (e.key === "Enter") {
        handleSubmit(e);
      }
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-800 p-4">
      <Helmet>
        <title>Reset Password | Convogenius AI</title>
      </Helmet>
      <div className="bg-gray-900 max-w-md w-full lg:w-2/3 xl:w-1/2 p-8 lg:p-12 rounded-2xl shadow-md flex flex-col justify-center items-center lg:items-start">
        <h2 className="text-3xl font-semibold text-center mb-4 lg:text-left text-white">
          Reset Password At ConvoGenius
        </h2>
        <form onSubmit={handleSubmit} className="w-full">
          {currentStep === 1 && (
            <div className="mb-4 w-full relative">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-white mb-1"
              >
                Email
              </label>
              <div className="relative flex items-center">
                <EnvelopeIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  onKeyDown={(e) => handleKeyDown(e, 1)}
                  className={`pl-10 p-3 w-full bg-gray-900 border ${
                    emailError ? "border-red-500" : "border-gray-500"
                  } rounded-md text-white focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none`}
                  placeholder="Your email"
                  required
                />
              </div>
              {emailError && (
                <p className="text-sm text-red-500 mt-1">{emailError}</p>
              )}
              <button
                type="button"
                onClick={handleNextStep}
                className={`mt-4 py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full ${
                  !email ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!email}
              >
                Next
              </button>
            </div>
          )}

          {currentStep === 2 && (
            <div className="mb-4 w-full relative">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-white mb-1"
              >
                Username
              </label>
              <div className="relative flex items-center">
                <UserIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={handleUsernameChange}
                  onKeyDown={(e) => handleKeyDown(e, 2)}
                  className={`pl-10 p-3 w-full text-white bg-gray-900 border ${
                    usernameError ? "border-red-500" : "border-gray-500"
                  } rounded-md text-white focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none`}
                  placeholder="Your username"
                  required
                />
              </div>
              {usernameError && (
                <p className="text-sm text-red-500 mt-1">{usernameError}</p>
              )}
              <div className="flex flex-col">
                <button
                  type="button"
                  onClick={handleNextStep}
                  className={`mt-4 py-2 px-4 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full ${
                    !username ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={!username}
                >
                  Next
                </button>
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="mt-4 py-2 px-4 bg-gray-600 text-white rounded-2xl hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Previous
                </button>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div className="mb-4 w-full">
              <div className="relative mb-4">
                <label
                  htmlFor="old-password"
                  className="block text-sm font-medium text-white mb-1"
                >
                  Old Password
                </label>
                <div className="relative flex items-center">
                  <LockClosedIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                  <input
                    type={showOldPassword ? "text" : "password"}
                    id="old-password"
                    name="old-password"
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    onKeyDown={(e) => handleKeyDown(e, 3)}
                    className="pl-10 p-3 w-full bg-gray-900 border border-gray-500 rounded-md text-white focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none"
                    placeholder="Your old password"
                    required
                  />
                  <div
                    className="absolute right-3 cursor-pointer"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? (
                      <EyeSlashIcon className="h-6 w-6 text-gray-400" />
                    ) : (
                      <EyeIcon className="h-6 w-6 text-gray-400" />
                    )}
                  </div>
                </div>
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="new-password"
                  className="block text-sm font-medium text-white mb-1"
                >
                  New Password
                </label>
                <div className="relative flex items-center">
                  <LockClosedIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="new-password"
                    name="new-password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    onKeyDown={(e) => handleKeyDown(e, 3)}
                    className={`pl-10 p-3 w-full bg-gray-900 border ${
                      passwordError ? "border-red-500" : "border-gray-500"
                    } rounded-md text-white focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none`}
                    placeholder="Your new password"
                    required
                  />
                  <div
                    className="absolute right-3 cursor-pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <EyeSlashIcon className="h-6 w-6 text-gray-400" />
                    ) : (
                      <EyeIcon className="h-6 w-6 text-gray-400" />
                    )}
                  </div>
                </div>
                {passwordError && (
                  <p className="text-sm text-red-500 mt-1">{passwordError}</p>
                )}
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium text-white mb-1"
                >
                  Confirm Password
                </label>
                <div className="relative flex items-center">
                  <LockClosedIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm-password"
                    name="confirm-password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onKeyDown={(e) => handleKeyDown(e, 3)}
                    className={`pl-10 p-3 w-full bg-gray-900 border ${
                      confirmPasswordError
                        ? "border-red-500"
                        : "border-gray-500"
                    } rounded-md text-white focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none`}
                    placeholder="Confirm your new password"
                    required
                  />
                  <div
                    className="absolute right-3 cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <EyeSlashIcon className="h-6 w-6 text-gray-400" />
                    ) : (
                      <EyeIcon className="h-6 w-6 text-gray-400" />
                    )}
                  </div>
                </div>
                {confirmPasswordError && (
                  <p className="text-sm text-red-500 mt-1">
                    {confirmPasswordError}
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <button
                  type="submit"
                  className={`mt-4 py-2 px-4 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full ${
                    !newPassword ||
                    !confirmPassword ||
                    passwordError ||
                    confirmPasswordError
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={
                    !newPassword ||
                    !confirmPassword ||
                    passwordError ||
                    confirmPasswordError
                  }
                >
                  {loading ? (
                    <ClipLoader size={24} color={"#ffffff"} />
                  ) : (
                    "Reset Password"
                  )}
                </button>
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="mt-4 py-2 px-4 bg-gray-600 text-white rounded-2xl hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Previous
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PasswordReset;
