import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ path }) => {
  return (
    <nav className="mb-4">
      <ol className="list-reset flex text-blue-600 dark:text-blue-400">
        {path.map((segment, index) => (
          <li key={index} className="flex items-center">
            {index > 0 && <span className="mx-2">/</span>}
            {index < path.length - 1 ? (
              <Link to={segment.path} className="hover:underline">
                {segment.label}
              </Link>
            ) : (
              <span>{segment.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
