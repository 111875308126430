import React, { useState, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import { Link } from "react-router-dom";

import MainSection from "../../components/home/MainSection/MainSection";
import WhyChooseSection from "../../components/home/MainSection/WhyChooseSection";
import TimelineSection from "../../components/home/MainSection/TimelineSection";
import BlogSection from "../../components/home/blog/BlogSection";
import Helmet from "../../components/home/helmet/helmet";

const HomePage = () => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const openInfoModal = () => {
    setIsInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  return (
    <>
      <Helmet />
      <Analytics />
      <MainSection />
      <WhyChooseSection />
      <TimelineSection />

      <BlogSection />
    </>
  );
};

export default HomePage;
