import React from "react";

const TermsOfServicePage = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6 flex items-center justify-center">
      <div className="max-w-3xl w-full bg-white p-8 rounded-lg shadow-lg lg:mb-0 mb-20">
        <h1 className="text-3xl font-semibold mb-6 text-gray-800">
          Terms of Service
        </h1>
        <p className="text-gray-600 mb-4">Last updated: October 9, 2024</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">
          1. Acceptance of Terms
        </h2>
        <p className="text-gray-600 mb-4">
          By accessing and using our services, you agree to be bound by these
          terms and conditions. If you do not agree, you may not access the
          services.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">
          2. Changes to Terms
        </h2>
        <p className="text-gray-600 mb-4">
          We reserve the right to modify these terms at any time. Any changes
          will be effective immediately upon posting on this page. Your
          continued use of the services after changes are posted constitutes
          your acceptance of the new terms.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">
          3. User Responsibilities
        </h2>
        <p className="text-gray-600 mb-4">
          Users are responsible for maintaining the confidentiality of their
          account information and for all activities that occur under their
          account.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">
          4. Limitation of Liability
        </h2>
        <p className="text-gray-600 mb-4">
          In no event shall we be liable for any direct, indirect, incidental,
          special, or consequential damages arising from the use of our
          services.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">
          5. Governing Law
        </h2>
        <p className="text-gray-600 mb-4">
          These terms shall be governed by and construed in accordance with the
          laws of the jurisdiction in which we operate.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">
          6. Contact Us
        </h2>
        <p className="text-gray-600 mb-4">
          If you have any questions about these terms, please contact us at
          saipulimdn@gmail.com.
        </p>

        <p className="text-gray-600 mt-8">Thank you for using our services!</p>
      </div>
    </div>
  );
};

export default TermsOfServicePage;
