// src/components/SkeletonCard.js
import React from "react";

const SkeletonCard = () => {
  return (
    <div className="border rounded p-4 shadow-md bg-white  animate-pulse">
      <div className="h-6 bg-gray-300 rounded mb-2"></div>
      <div className="h-4 bg-gray-300 rounded mb-2"></div>
      <div className="h-4 bg-gray-300 rounded mb-2"></div>
      <div className="h-4 bg-gray-300 rounded mb-2"></div>
    </div>
  );
};

export default SkeletonCard;
