// src/components/BlogSection.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch blogs from the API
    axios
      .get(
        "https://convogenius-backend-production.up.railway.app/api/v1/blogs/get-blogs"
      )
      .then((response) => {
        const blogs = response.data.data; // Access the 'data' array from the JSON response
        setBlogs(blogs.slice(0, 3)); // Only take the latest 3 blogs
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <div className="container mx-auto px-4 md:px-8 mt-8 mb-8">
      {/* Section Header */}
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-blue-600 mb-2">What's New</h1>
        <p className="text-lg text-gray-700 mb-4">
          Check out the latest announcements and updates from Convogenius.
        </p>
        {/* Button to View All Blogs */}
        <Link to="/blog">
          <button className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300">
            View All Blogs
          </button>
        </Link>
      </div>

      {/* Blog Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {blogs.map((blog) => (
          <Link key={blog.id} to={`/detail_blog/${blog.slug}`}>
            <div className="border rounded-lg shadow-lg border-blue-600 p-6 transition duration-300">
              <h2 className="text-xl font-bold mb-2">
                {truncateText(blog.title, 80)}
              </h2>
              <p className="text-gray-700 mb-4">
                {truncateText(blog.description, 70)}
              </p>
              <p className="text-gray-500 text-sm">
                {formatDate(blog.updated_at)}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogSection;
