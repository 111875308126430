// components/PromptSelector.jsx
import React from "react";
import PromptCard from "./PromptCard"; // Adjust the import path as necessary

const prompts = [
  "Create a Food Branding Description",
  "Create a Laundry Service Branding Description",
  "Create a Branding Description for my Electronics Store",
  "Create a Branding Description for my iced tea drink",
];

const PromptSelector = ({ onPromptClick }) => {
  return (
    <div className="flex flex-col items-center mt-40 bg-white">
      <img src="images/logo.png" alt="Logo" className="mb-4 w-20 h-20" />
      <div className="grid grid-cols-2 gap-4">
        {prompts.map((text) => (
          <PromptCard
            key={text}
            text={text}
            onClick={() => onPromptClick(text)}
          />
        ))}
      </div>
    </div>
  );
};

export default PromptSelector;
