const InfoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-70"
      style={{ pointerEvents: "auto", backdropFilter: "blur(4px)" }}
    >
      <div className="bg-white p-8 rounded-lg max-w-lg w-full mx-4 md:mx-0 relative z-10">
        <h2 className="text-2xl font-semibold mb-4">Introducing Convogenius</h2>
        <p className="text-lg mb-4">
          Convogenius AI is designed to enhance product branding through
          AI-powered solutions such as chat, image, and music generation. It
          also assists businesses in optimizing operations and staying ahead of
          trends for product content strategies.
        </p>

        <button
          onClick={onClose}
          className="bg-blue-600 text-white px-4 py-2 rounded-md transition duration-300 hover:bg-blue-700"
        >
          Close
        </button>
      </div>

      {/* Background Overlay to Lock Focus */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-0"
        onClick={onClose}
      ></div>
    </div>
  );
};

export default InfoModal;
