// components/ImageHeader.jsx
import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid"; // Adjust the import according to your icon library
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const ImageHeader = () => {
  return (
    <header className="bg-white text-gray-800 py-2 px-4 flex justify-between items-center">
      <a href="/cht-ai">
        <button className="flex items-center px-4 py-2  text-gray-800 hover:text-gray-500 rounded transition duration-200 text-sm">
          <ArrowLeftIcon className="h-5 w-5 mr-2 text-sm" />
          Back To Chat AI
        </button>
      </a>
      <a href="/music-ai">
        <div className="flex items-center">
          <p className="text-sm mr-2 text-gray-800 hover:text-gray-500">
            Next to AI Music
          </p>
          <ArrowRightIcon className="h-5 w-5 text-gray-800 hover:text-gray-200" />
        </div>
      </a>
    </header>
  );
};

export default ImageHeader;
