import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ChatHeader from "../../components/AI/ChatAi/ChatHeader";
import SkeletonLoader from "../../components/AI/ChatAi/SkeletonLoader";
import PromptSelector from "../../components/AI/ChatAi/PromptSelector";
import Message from "../../components/AI/ChatAi/Message"; // Sesuaikan path sesuai struktur folder Anda
import PromptCard from "../../components/AI/ChatAi/PromptCard"; // Sesuaikan path sesuai struktur folder Anda

const ChatPage = () => {
  const { chatId } = useParams();
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [responseStarted, setResponseStarted] = useState(false);
  const [showPrompts, setShowPrompts] = useState(!chatId);

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");
    setUserEmail(email);

    fetchUserInfo(email);
    if (chatId) {
      fetchChatHistory(email, chatId);
    }
  }, [chatId]);

  const fetchUserInfo = (email) => {
    const token = localStorage.getItem("authToken");
    const endpoint = `https://convogenius-backend-production.up.railway.app/api/v1/dashboard/${email}`;

    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        // Access the data field from the result
        const data = result.data;

        // Check if data is an array and has at least one user object
        if (Array.isArray(data) && data.length > 0) {
          const userInfo = data[0]; // Access the first user object
          setUserInfo(userInfo);
        } else {
          console.error("No user data found");
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const fetchChatHistory = (email, chatId) => {
    const endpoint = `https://convogenius-backend-production.up.railway.app/api/v1/chat-detail/${email}/${chatId}`;

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch chat history");
        }
        return response.json();
      })
      .then((data) => {
        const chatHistory = data.data.messages || []; // Accessing 'messages' under 'data'
        const formattedMessages = chatHistory.flatMap((msg) => [
          {
            id: `${msg.id}-user`,
            text: msg.user_input,
            sender: "user",
            timestamp: msg.timestamp,
          },
          {
            id: `${msg.id}-bot`,
            text: msg.response,
            sender: "bot",
            timestamp: msg.timestamp,
          },
        ]);
        setMessages(formattedMessages);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    const userMessage = {
      id: messages.length + 1,
      text: newMessage,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setNewMessage("");
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    const isGreeting = checkIfGreeting(newMessage);

    if (isGreeting) {
      const greetingResponse = {
        id: messages.length + 2,
        text: "",
        type: "text",
        sender: "bot",
        timestamp: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, greetingResponse]);
      displayResponseWordByWord(
        greetingResponse.id,
        "Hello! How can I assist you?",
        timer
      );
    } else {
      queryText({ inputs: newMessage })
        .then((data) => {
          const generatedText = data.generated_text;
          const responseMessage = {
            id: messages.length + 2,
            text: "",
            type: "text",
            sender: "bot",
            timestamp: new Date().toISOString(),
          };
          setMessages((prevMessages) => [...prevMessages, responseMessage]);
          displayResponseWordByWord(responseMessage.id, generatedText, timer);
        })
        .catch((error) => {
          setError(error.message);
          clearInterval(timer);
          setLoading(false);
        });
    }

    saveChatHistory([...messages, userMessage]);
  };

  const displayResponseWordByWord = (messageId, responseText, timer) => {
    const words = responseText.split(" ");
    let index = 0;
    setResponseStarted(true);
    const wordInterval = setInterval(() => {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((msg) => {
          if (msg.id === messageId && words[index] !== undefined) {
            return {
              ...msg,
              text: msg.text + (msg.text ? " " : "") + words[index],
            };
          }
          return msg;
        });
        return updatedMessages;
      });
      index++;
      if (index >= words.length) {
        clearInterval(wordInterval);
        clearInterval(timer);
        setLoading(false);
        setResponseStarted(false);
      }
    }, 300);
  };

  const queryText = async (data) => {
    let apiUrl = "";

    if (chatId) {
      // If chatId exists in the URL, use the API with chatId
      apiUrl = `https://convogenius-backend-production.up.railway.app/api/v1/chat/${chatId}/${userEmail}`;
    } else {
      // If no chatId, use the default API
      apiUrl = `https://convogenius-backend-production.up.railway.app/api/v1/chat-ai/${userEmail}`;
    }

    const response = await fetch(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    });

    const result = await response.json();
    return result;
  };

  const checkIfGreeting = (message) => {
    const greetings = ["hi", "hello", "hai", "halo"];
    const words = message.toLowerCase().split(" ");
    return words.some((word) => greetings.includes(word));
  };

  const handleClearMessage = (id) => {
    const updatedMessages = messages.filter((message) => message.id !== id);
    setMessages(updatedMessages);
    saveChatHistory(updatedMessages);
  };

  const handleCopyMessage = (text) => {
    navigator.clipboard.writeText(text);
  };

  const saveChatHistory = (messages) => {
    const userEmail = localStorage.getItem("userEmail");
    let storedHistories =
      JSON.parse(localStorage.getItem(`${userEmail}_chatHistories`)) || {};

    const lastKey = Object.keys(storedHistories).length;

    storedHistories[lastKey + 1] = {
      title: "Chat Session",
      messages,
      date: new Date().toLocaleString(),
    };

    localStorage.setItem(
      `${userEmail}_chatHistories`,
      JSON.stringify(storedHistories)
    );
  };

  const handlePromptClick = (prompt) => {
    const message = {
      id: messages.length + 1,
      text: prompt,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    queryText({ inputs: prompt })
      .then((data) => {
        const generatedText = data.generated_text;
        const responseMessage = {
          id: messages.length + 2,
          text: "",
          type: "text",
          sender: "bot",
          timestamp: new Date().toISOString(),
        };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
        displayResponseWordByWord(responseMessage.id, generatedText, timer);
      })
      .catch((error) => {
        setError(error.message);
        clearInterval(timer);
        setLoading(false);
      });

    saveChatHistory([...messages, message]);
  };

  return (
    <div className="flex flex-col h-screen bg-white text-gray-800">
      <Helmet>
        <title>Branding Deksripsi With AI | {userEmail}</title>
      </Helmet>
      <ChatHeader />
      {showPrompts && <PromptSelector onPromptClick={handlePromptClick} />}{" "}
      <main className="flex-1 p-4 overflow-y-auto">
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            onClear={() => handleClearMessage(message.id)}
            onCopy={() => handleCopyMessage(message.text)}
          />
        ))}
        {loading && !responseStarted && <SkeletonLoader />}{" "}
        {/* Show SkeletonLoader */}
      </main>
      <form
        onSubmit={handleSendMessage}
        className="flex justify-center items-center py-2 px-4 lg:py-2" // Reduced vertical padding
      >
        <div className="flex items-center max-w-2xl w-full">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            rows={1} // Starting with a single row; it will expand as needed
            className="flex-1 px-4 py-2 bg-white rounded-lg focus:outline-none focus:bg-gray-200 text-gray-800 border border-gray-300 resize-none overflow-y-auto shadow-md"
            style={{
              maxHeight: "200px", // Optional: max height limit for scrolling
              paddingRight: "2.5rem", // Adjust for aesthetics
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevent the default new line behavior
                handleSendMessage(e); // Call the function to send the message
              }
            }}
          />
          <button
            type="submit"
            className="ml-2 bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700 focus:bg-blue-700 focus:outline-none"
          >
            {loading ? (
              <span className="text-sm">{(timeElapsed / 10).toFixed(1)}s</span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
              </svg>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const ChatAI = () => {
  const [messages, setMessages] = useState([]); // Misal data message
  const handleClearMessage = (id) => {
    setMessages((prev) => prev.filter((msg) => msg.id !== id)); // Fungsi hapus message
  };

  const handleCopyMessage = (text) => {
    console.log("Copying:", text); // Implementasi logika copy
  };

  return (
    <div>
      {messages.map((message) => (
        <Message
          key={message.id}
          message={message}
          onClear={() => handleClearMessage(message.id)}
          onCopy={handleCopyMessage}
        />
      ))}
      {/* Contoh penggunaan PromptCard */}
      <PromptCard
        text="This is a prompt card"
        onClick={() => console.log("Prompt clicked!")}
      />
    </div>
  );
};

export default ChatPage;
