import React from "react";

const PromptCard = ({ text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-white border border-blue-600 text-gray-800 hover:text-white p-4 mr-2 ml-2 rounded-lg shadow-md cursor-pointer hover:bg-blue-700 transition duration-300"
    >
      {text}
    </div>
  );
};

export default PromptCard;
