import React from "react";
import { ClipLoader } from "react-spinners"; // Add this line

const RecordForm = ({
  formData,
  handleChange,
  handleSubmit,
  isLoading,
  handleModalClose,
}) => {
  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {Object.keys(formData).map((key) => (
          <div className="flex flex-col" key={key}>
            <label htmlFor={key} className="text-gray-700 mb-2">
              {key
                .replace("_", " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </label>
            <input
              type={
                key === "unit_price" ||
                key === "quantity_sold" ||
                key === "bagi_hasil"
                  ? "number"
                  : "text"
              }
              id={key}
              name={key}
              value={formData[key] || ""}
              onChange={handleChange}
              placeholder={`Enter ${key.replace("_", " ")}`}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        ))}
      </div>
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={handleModalClose}
          className="bg-gray-500 text-white px-4 py-2 rounded-xl hover:bg-gray-600"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded-xl hover:bg-blue-700 flex items-center"
          disabled={isLoading}
        >
          {isLoading && (
            <ClipLoader size={20} color={"#ffffff"} className="mr-2" />
          )}
          Submit
        </button>
      </div>
    </form>
  );
};

export default RecordForm;
