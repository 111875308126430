const WhyChooseSection = () => (
  <div className="text-center pt-10 pb-10">
    <h2 className="text-4xl font-extrabold text-gray-900 mb-6">
      Why Choose Convogenius AI?
    </h2>

    <p className="text-lg text-gray-700 mb-10">
      Convogenius AI offers a blend of advanced technology, efficiency, and
      versatility to empower your business and streamline content creation.
    </p>

    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="border-l-4 border-emerald-300 p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-2xl text-left font-bold mb-2 text-gray-800">
          Advanced Capabilities
        </h1>
        <p className="text-base text-left text-gray-800">
          Convogenius AI is designed with cutting-edge technology to generate
          high-quality, coherent text and realistic images, making it a powerful
          tool for various applications.
        </p>
      </div>

      <div className="border-l-4 border-yellow-300 p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-2xl text-left font-bold mb-2 text-gray-800">
          Efficiency
        </h1>
        <p className="text-base text-left text-gray-800">
          By automating the generation of text and images, Convogenius AI speeds
          up content creation processes, saving time and resources.
        </p>
      </div>

      <div className="border-l-4 border-rose-500 p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-2xl text-left font-bold mb-2 text-gray-800">
          Versatility
        </h1>
        <p className="text-base text-left text-gray-800">
          Convogenius AI can be applied across different domains such as
          marketing, entertainment, and education, offering tailored solutions.
        </p>
      </div>
    </div>
  </div>
);

export default WhyChooseSection;
