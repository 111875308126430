import React, { useEffect, useState } from "react";

const DashboardHeader = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null); // To capture any errors

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    setUserEmail(email);

    // Fetch user info from backend
    if (email) {
      fetchUserInfo(email);
    }
  }, []); // Add empty dependency array to run once on mount

  const fetchUserInfo = (email) => {
    const token = localStorage.getItem("authToken");
    const endpoint = `https://convogenius-backend-production.up.railway.app/api/v1/dashboard/${email}`;

    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUserInfo(data.user);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <section className="w-full bg-blue-600 p-6 rounded-3xl mb-10 text-white flex flex-col lg:flex-row items-center lg:items-center">
      <div className="lg:w-1/2 mb-4 lg:mb-0 lg:mr-4 flex flex-col justify-center text-center lg:text-left">
        <h1 className="text-2xl lg:text-3xl font-bold mb-2 animate-slide-in-left">
          Welcome to Your Dashboard{" "}
          <span className="text-blue-600 bg-white px-3 py-1 rounded-xl mb-2 mt-2 block lg:inline-block">
            {userInfo?.Username || userEmail}
          </span>
        </h1>
        <p className="text-sm lg:text-base animate-slide-in-left">
          Here you can find the latest trends in social media and keep track of
          your sales data. Use the sections below to explore trending topics on
          YouTube, TikTok, and Twitter, and to manage your sales performance.
        </p>
      </div>
      <img
        src="images/dashboard/hello-user.png" // Replace with the actual image path
        alt="Dashboard Introduction"
        className="lg:w-1/2 w-full h-auto object-cover rounded-lg animate-slide-in-right mt-4 lg:mt-0"
      />
    </section>
  );
};

export default DashboardHeader;
