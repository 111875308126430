// components/VideoPopup.jsx
import React from "react";

export default function VideoPopup({
  video,
  closePopup,
  isFullDescription,
  setIsFullDescription,
}) {
  const truncateText = (text, maxLength) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-2xl relative max-h-[90vh] overflow-y-auto">
        <button
          className="absolute top-1 right-1 text-gray-600 hover:text-gray-800"
          onClick={closePopup}
        >
          ✕
        </button>
        <iframe
          className="w-full h-64 rounded-lg"
          src={`https://www.youtube.com/embed/${video.id}`}
          title={video.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <h4 className="font-semibold text-xl mt-4">{video.title}</h4>
        <p className="mt-2 text-gray-600">
          {isFullDescription ? (
            <span>{video.description}</span>
          ) : (
            <>
              <span>{truncateText(video.description, 50)}</span>
              <button
                className="text-blue-500 ml-2"
                onClick={() => setIsFullDescription(true)}
              >
                Read More
              </button>
            </>
          )}
        </p>
      </div>
    </div>
  );
}
