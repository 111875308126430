import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { PlusIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths, subMonths, format } from "date-fns";
import { ClipLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const MakeBookkeeping = () => {
  const [monthYears, setMonthYears] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newMonthYear, setNewMonthYear] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const navigate = useNavigate();

  const email = localStorage.getItem("userEmail");

  // Dapatkan tanggal hari ini
  const today = new Date();

  // Dapatkan bulan sebelumnya dan bulan ini sebagai batasan tanggal
  const minDate = subMonths(today, 11); // Membatasi hingga 11 bulan sebelumnya
  const maxDate = today; // Batas maksimal adalah bulan ini

  useEffect(() => {
    fetchMonthYears();
  }, []);

  useEffect(() => {
    if (modalIsOpen || deleteModalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalIsOpen, deleteModalIsOpen]);

  const fetchMonthYears = async () => {
    setLoadingData(true);
    try {
      const response = await axios.get(
        `https://convogenius-backend-production.up.railway.app/api/v1/monthyear/${email}`
      );

      // Check if the response contains the expected structure and data
      if (response.data && Array.isArray(response.data.data)) {
        const monthYearsList = response.data.data.map((item) => ({
          id: item.id,
          month_year: item.month_year,
        }));

        // Sort monthYearsList by year (descending) and month (ascending)
        monthYearsList.sort((a, b) => {
          const [yearA, monthA] = a.month_year.split("-");
          const [yearB, monthB] = b.month_year.split("-");
          if (yearA !== yearB) return yearB - yearA; // Sort years descending
          return monthA - monthB; // Sort months ascending
        });

        setMonthYears(monthYearsList);
      } else {
        console.error("Error: month_years data is not available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const createMonthYear = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `https://convogenius-backend-production.up.railway.app/api/v1/monthyear/${email}`,
        {
          month_year: format(newMonthYear, "yyyy-MM"),
        }
      );
      fetchMonthYears();
      setModalIsOpen(false);
      setNewMonthYear(new Date());
      toast.success("Record created successfully!");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          toast.error("Error: Internal server error.");
        } else if (error.response.status === 409) {
          toast.error("Error: Data already exists.");
        } else {
          toast.error("Error creating data.");
        }
      } else {
        console.error("Error creating data:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMonthYear = async () => {
    setIsLoading(true);
    try {
      await axios.delete(
        `https://convogenius-backend-production.up.railway.app/api/v1/monthyear/${selectedMonthYear}`
      );
      fetchMonthYears();
      setDeleteModalIsOpen(false);
      toast.success("Record deleted successfully!");
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Error deleting record.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 relative bg-gray-50 min-h-screen">
      <Helmet>
        <title>Create your bookkeeping data | Convogenius</title>
      </Helmet>
      <ToastContainer />
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
          <div className="flex flex-col items-center">
            <ClipLoader size={50} color={"#ffffff"} />
            <p className="text-white mt-4">Loading...</p>
          </div>
        </div>
      )}
      <div
        className={`flex flex-col ${
          isLoading ? "opacity-50 pointer-events-none" : ""
        }`}
      >
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-4 md:mb-0">
            Bookkeeping Records
          </h1>
          <button
            className="bg-blue-600 text-white px-6 py-3 rounded-lg flex items-center justify-center hover:bg-blue-700 transition duration-200 shadow-lg"
            onClick={() => setModalIsOpen(true)}
          >
            <PlusIcon className="h-6 w-6 mr-2" />
            <span className="hidden md:inline">Add Year and Month</span>
          </button>
        </div>
        <ul className="space-y-4">
          {loadingData ? (
            <li className="p-4 rounded shadow bg-white">
              <Skeleton count={5} height={40} />
            </li>
          ) : monthYears.length === 0 ? (
            <li className="p-4 rounded shadow bg-white text-center text-gray-700">
              There is no data, create year and month data first
            </li>
          ) : (
            monthYears.map((monthYear, index) => (
              <li
                key={index}
                className="bg-white p-4 rounded-lg shadow hover:bg-gray-100 transition duration-200 flex justify-between items-center"
              >
                <span className="text-lg font-medium text-gray-700">
                  {monthYear.month_year}
                </span>
                <div className="flex space-x-3">
                  <button
                    className="text-default hover:text-blue-900 transition duration-200"
                    onClick={() =>
                      navigate(`/create-bookkeeping/${monthYear.id}`)
                    }
                  >
                    <EyeIcon className="h-6 w-6" />
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700 transition duration-200"
                    onClick={() => {
                      setSelectedMonthYear(monthYear.id);
                      setDeleteModalIsOpen(true);
                    }}
                  >
                    <TrashIcon className="h-6 w-6" />
                  </button>
                </div>
              </li>
            ))
          )}
        </ul>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto mt-20 z-40"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4"
        >
          <h2 className="text-2xl font-bold mb-6">Create Year and Month</h2>
          <DatePicker
            selected={newMonthYear}
            onChange={(date) => setNewMonthYear(date)}
            dateFormat="yyyy-MM"
            showMonthYearPicker
            className="border border-gray-300 p-3 w-full mb-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
            placeholderText="Enter month and year (e.g., 2024-07)"
            minDate={minDate} // Membatasi tanggal paling awal
            maxDate={maxDate} // Membatasi tanggal paling akhir
          />
          <div className="flex justify-end space-x-3">
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-200"
              onClick={() => setModalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
              onClick={createMonthYear}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={deleteModalIsOpen}
          onRequestClose={() => setDeleteModalIsOpen(false)}
          className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto mt-20 z-40"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4"
        >
          <h2 className="text-2xl font-bold mb-6">Confirm Delete</h2>
          <p className="mb-6 text-gray-700">
            Are you sure you want to delete the record for {selectedMonthYear}?
          </p>
          <div className="flex justify-end space-x-3">
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-200"
              onClick={() => setDeleteModalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
              onClick={deleteMonthYear}
              disabled={isLoading}
            >
              Delete
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MakeBookkeeping;
