// components/TrendingVideoCard.jsx
import React from "react";

export default function TrendingVideoCard({
  video,
  index,
  onClick,
  lastVideoRef,
}) {
  return (
    <div
      ref={lastVideoRef}
      className="group text-gray-800 bg-white border border-blue-600 p-4 rounded-lg shadow-lg flex flex-col items-center transition duration-300 hover:bg-blue-800 hover:text-white cursor-pointer"
      onClick={() => onClick(video)}
    >
      <span className="text-gray-500 text-sm font-semibold mb-2 group-hover:text-white">
        #{index + 1}
      </span>
      <img
        src={video.thumbnail_url}
        alt={video.title}
        className="w-full h-40 object-cover rounded-lg mb-4 group-hover:opacity-80 transition duration-300"
      />
      <h4 className="font-semibold mb-2 text-center group-hover:text-white">
        {video.title}
      </h4>
    </div>
  );
}
