import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom"; // For navigation
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { Helmet } from "react-helmet";

const ChangeEmailPage = () => {
  const [oldEmail, setOldEmail] = useState(""); // State for old email
  const [newEmail, setNewEmail] = useState(""); // State for new email
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate(); // Use navigate to redirect user

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page refresh on form submit
    setLoading(true);

    const requestData = {
      old_email: oldEmail.trim(),
      new_email: newEmail.trim(),
    };

    try {
      const response = await fetch(
        "https://convogenius-backend-production.up.railway.app/api/v1/update-email", // API endpoint
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        toast.success("Email updated successfully!");

        // Clear email from localStorage and redirect to login page
        localStorage.removeItem("email"); // Assuming email is stored under "email"

        setTimeout(() => {
          navigate("/login"); // Redirect to the login page
        }, 1500); // Delay before redirecting to show toast
      } else if (response.status === 400) {
        toast.error("Invalid email format. Please check the details.");
      } else if (response.status === 404) {
        toast.error("Old email not found.");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Change Email | Convogenius</title>
      </Helmet>
      <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-6 text-gray-900">Change Email</h1>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Old Email Field */}
          <div className="relative">
            <label
              htmlFor="oldEmail"
              className="block text-gray-700 font-medium"
            >
              Old Email
            </label>
            <div className="relative">
              <input
                type="email"
                id="oldEmail"
                value={oldEmail}
                onChange={(e) => setOldEmail(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 pl-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Enter your old email"
              />
              <EnvelopeIcon className="h-5 w-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* New Email Field */}
          <div className="relative">
            <label
              htmlFor="newEmail"
              className="block text-gray-700 font-medium"
            >
              New Email
            </label>
            <div className="relative">
              <input
                type="email"
                id="newEmail"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 pl-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Enter your new email"
              />
              <EnvelopeIcon className="h-5 w-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full bg-blue-600 text-white py-2 rounded-lg mt-4 ${
              loading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
            }`}
          >
            {loading ? "Updating..." : "Update Email"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangeEmailPage;
