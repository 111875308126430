// components/Message.jsx
import { ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/solid";

const Message = ({ message, onClear, onDownload }) => {
  const { text, sender, timestamp, type } = message;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div
      className={`flex ${
        sender === "user" ? "justify-end" : "justify-start"
      } mb-2`}
    >
      {sender !== "user" && (
        <img
          src="images/logo.png"
          alt="AI Profile"
          className="w-8 h-8 border border-gray-500 rounded-full mr-2"
        />
      )}

      <div className={`max-w-md ${sender === "user" ? "ml-auto" : "mr-auto"}`}>
        <div
          className={`rounded-lg ${
            sender === "user"
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-900"
          } py-2 px-4 break-words`}
        >
          {type === "image" ? (
            <img src={text} alt="Generated" className="rounded-lg" />
          ) : (
            text
          )}
        </div>
        <div className="text-xs text-gray-500 mt-1 flex justify-between items-center">
          <span>{formatDate(timestamp)}</span>
          <div className="flex">
            {type === "image" && (
              <button
                onClick={onDownload}
                className="ml-2 text-gray-800 hover:text-gray-200 focus:outline-none"
              >
                <ArrowDownTrayIcon className="h-4 w-4" />
              </button>
            )}
            <button
              onClick={onClear}
              className="ml-2 text-red-700 hover:text-red-800 focus:outline-none"
            >
              <TrashIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
