// components/SkeletonLoader.jsx
import React from "react";

export default function SkeletonLoader({ count }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="bg-gray-200 p-4 rounded-lg shadow-lg flex flex-col items-center transition duration-300 animate-pulse"
        >
          <div className="w-full h-40 bg-gray-300 rounded-lg mb-4"></div>
          <div className="w-3/4 h-6 bg-gray-300 rounded mb-2"></div>
        </div>
      ))}
    </div>
  );
}
