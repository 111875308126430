import React from "react";
import { Helmet } from "react-helmet";

const ProfileCompanyPage = () => {
  return (
    <>
      <Helmet>
        <title>Welcome to Convogenius - Innovative Branding Solutions</title>
        <meta
          name="description"
          content="Convogenius is an innovative company dedicated to helping entrepreneurs brand their products using AI. Discover our suite of tools for branding success."
        />
        <meta
          name="keywords"
          content="Convogenius, AI branding, product branding, entrepreneurs, innovative tools"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.convogenius.my.id/profile-company"
        />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Welcome to Convogenius" />
        <meta
          property="og:description"
          content="Explore Convogenius, your partner in innovative AI branding solutions for entrepreneurs."
        />
        <meta
          property="og:image"
          content="https://www.convogenius.my.id/path/to/image.jpg"
        />
        <meta
          property="og:url"
          content="https://www.convogenius.my.id/profile-company"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 p-8 flex flex-col items-center">
        <h1 className="text-5xl font-bold text-gray-800 mb-6 text-center">
          Welcome to Convogenius
        </h1>
        <p className="text-lg text-gray-600 mb-12 text-center max-w-3xl">
          Convogenius is an innovative company dedicated to helping
          entrepreneurs brand their products using AI. From descriptive content
          to images and music, we provide a comprehensive suite of tools to
          elevate your brand presence.
        </p>

        {/* Section 1: Company Overview */}
        <div className="flex flex-col md:flex-row max-w-5xl bg-white shadow-md rounded-lg overflow-hidden mb-10 p-6">
          <div className="md:w-1/2 flex flex-col justify-center">
            <h2 className="text-3xl font-semibold text-gray-700 mb-4">
              Who We Are
            </h2>
            <p className="text-md text-gray-500">
              Convogenius is a company focused on assisting entrepreneurs in
              effectively branding their products through innovative AI
              solutions. Our mission is to empower businesses to express their
              unique stories through creative tools.
            </p>
          </div>
          <div className="md:w-1/2 flex items-center justify-center">
            <img
              src="images/profilecompany/WhoWeAre.webp" // Replace with your image URL
              alt="Company Overview"
              className="w-64 h-64 object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Section 2: Our Features */}
        <div className="flex flex-col md:flex-row max-w-5xl bg-white shadow-md rounded-lg overflow-hidden mb-10 p-6">
          <div className="md:w-1/2 flex flex-col justify-center">
            <h2 className="text-3xl font-semibold text-gray-700 mb-4">
              Our Features
            </h2>
            <p className="text-md text-gray-500 mb-4">
              At Convogenius, we offer a comprehensive suite of innovative tools
              designed to empower entrepreneurs in their branding efforts. Our
              services encompass a wide range of capabilities, from generating
              captivating product descriptions and high-quality images using
              advanced AI algorithms to seamlessly integrating music that
              enhances the overall branding experience. Furthermore, we provide
              robust sales analysis tools that enable businesses to track their
              performance metrics effectively, ensuring informed
              decision-making. Our bookkeeping assistance simplifies financial
              management, allowing entrepreneurs to focus on what they do best.
              Additionally, we keep you updated with access to trending YouTube
              content, providing inspiration and ideas to keep your brand fresh
              and engaging in the ever-evolving market landscape.
            </p>
          </div>
          <div className="md:w-1/2 flex items-center justify-center">
            <img
              src="images/profilecompany/OurFeatures.webp" // Replace with your image URL
              alt="Our Features"
              className="w-64 h-64 object-cover rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Section 3: Our Journey */}
        <div className="flex flex-col md:flex-row max-w-5xl bg-white shadow-md rounded-lg overflow-hidden p-6">
          <div className="md:w-1/2 flex flex-col justify-center">
            <h2 className="text-3xl font-semibold text-gray-700 mb-4">
              Our Journey
            </h2>
            <p className="text-md text-gray-500">
              Established in January 2024, Convogenius was founded with the
              vision of revolutionizing branding for businesses of all sizes.
              Our team of experts in AI, marketing, and design is dedicated to
              providing the best tools to help entrepreneurs thrive in the
              competitive market.
            </p>
          </div>
          <div className="md:w-1/2 flex items-center justify-center">
            <img
              src="images/profilecompany/OurJourney.webp" // Replace with your image URL
              alt="Our Journey"
              className="w-64 h-64 object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCompanyPage;
