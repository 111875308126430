import React, { useEffect, useState } from "react";

const EmailField = () => {
  const [email, setEmail] = useState("");

  // Retrieve the email from localStorage when the component mounts
  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-1">Email</label>
      <input
        type="text"
        value={email}
        readOnly
        className="border border-gray-300 rounded-md w-full p-2 bg-gray-200"
      />
    </div>
  );
};

export default EmailField;
