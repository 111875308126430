import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import SkeletonBlog from "../../components/SkeletonBlog/SkeletonBlog"; // Import Skeleton component
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"; // Import Breadcrumb component
import { Helmet } from "react-helmet"; // Import Helmet component
import parse from "html-react-parser"; // Import parse function

const DetailBlog = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://convogenius-backend-production.up.railway.app/api/v1/blogs/${slug}`
        );
        // Access the blog data properly from response
        setBlog(response.data.data);
      } catch (error) {
        console.error("Error fetching blog", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [slug]);

  if (loading) return <SkeletonBlog />; // Show skeleton loading

  if (!blog) return <div className="container mx-auto p-4">Blog not found</div>;

  // Function to format the date to "01 Agustus 2023"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  // Function to transform list items into ordered list
  const transformListItems = (htmlString) => {
    // Safely handle undefined or null htmlString
    if (!htmlString) return "";
    return htmlString.replace(/<li>/g, '<li class="numbered-li">');
  };

  // Breadcrumb path
  const breadcrumbPath = [
    { label: "Home", path: "/" },
    { label: "Blog", path: "/blog" },
    { label: blog.title, path: "#" },
  ];

  return (
    <div className="container mx-auto p-4">
      {/* Set dynamic page title */}
      <Helmet>
        <title>{blog.title}</title>
      </Helmet>

      {/* Breadcrumb Navigation */}
      <Breadcrumb path={breadcrumbPath} />

      {/* Card for blog details */}
      <div className="border rounded-lg p-6 shadow-md bg-white shadow-blue-600 mb-8">
        <h1 className="text-2xl font-bold mb-2 text-center">{blog.title}</h1>
        <p className="text-gray-600 mb-4 text-center">
          {formatDate(blog.updated_at)}
        </p>
        <p className="text-gray-800 mb-4 text-center">{blog.description}</p>
        {/* Render Blog body as HTML */}
        <div className="prose dark:prose-dark">
          {parse(transformListItems(blog.body))}
        </div>
      </div>
    </div>
  );
};

export default DetailBlog;
