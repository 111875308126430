// ChatHistoryTable.js
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { PulseLoader } from "react-spinners";

const ChatHistoryTable = ({
  isLoading,
  currentItems,
  animationDirection,
  handleView,
  handleDeleteClick,
  loading,
}) => {
  return (
    <div className="overflow-x-auto">
      <TransitionGroup
        component="table"
        className="min-w-full bg-white shadow-md rounded-lg overflow-hidden"
      >
        <thead className="bg-gray-200">
          <tr>
            <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
              Title
            </th>
            <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
              Created At
            </th>
            <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? Array.from({ length: 4 }).map((_, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 text-sm text-gray-700">
                    <Skeleton />
                  </td>
                  <td className="py-2 px-4 text-sm text-gray-500">
                    <Skeleton />
                  </td>
                  <td className="py-2 px-4 text-sm text-gray-500">
                    <Skeleton width={100} />
                  </td>
                </tr>
              ))
            : currentItems.map((chat) => (
                <CSSTransition
                  key={chat.id}
                  timeout={500}
                  classNames={
                    animationDirection === "next" ? "slide-next" : "slide-prev"
                  }
                >
                  <tr>
                    <td className="py-2 px-4 text-sm text-gray-700">
                      {chat.title}
                    </td>
                    <td className="py-2 px-4 text-sm text-gray-500">
                      {new Date(chat.created_at).toLocaleString()}
                    </td>
                    <td className="py-2 px-4 text-sm text-gray-500">
                      <button
                        onClick={() => handleView(chat.id)}
                        className="text-blue-500 hover:text-blue-700 mr-2"
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                      <button
                        onClick={() => handleDeleteClick(chat.id)}
                        className="text-red-500 hover:text-red-700"
                        disabled={loading[chat.id]}
                      >
                        {loading[chat.id] ? (
                          <PulseLoader size={8} color="#ff0000" />
                        ) : (
                          <FontAwesomeIcon icon={faTrash} />
                        )}
                      </button>
                    </td>
                  </tr>
                </CSSTransition>
              ))}
        </tbody>
      </TransitionGroup>
    </div>
  );
};

export default ChatHistoryTable;
