import React from "react";
import { FaTrash } from "react-icons/fa";

const ProfilePicture = ({
  profilePicture,
  setProfilePicture,
  setNewProfilePicture,
}) => {
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProfilePicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveProfilePicture = () => {
    setProfilePicture("https://via.placeholder.com/150");
    setNewProfilePicture(null);
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-1">Profile Picture</label>
      <div className="relative mb-2">
        <img
          src={profilePicture || "https://via.placeholder.com/150"}
          alt="Profile"
          className="w-24 h-24 object-cover rounded-full border-2 border-gray-300"
        />
        <input
          type="file"
          onChange={handleProfilePictureChange}
          className="absolute inset-0 opacity-0 cursor-pointer"
        />
      </div>
      <button
        onClick={handleRemoveProfilePicture}
        className="text-red-600 mt-2"
      >
        <FaTrash /> Remove Profile Picture
      </button>
    </div>
  );
};

export default ProfilePicture;
