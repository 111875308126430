import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import BannerPicture from "../../components/Account/ProfileEdit/BannerPicture";
import ProfilePicture from "../../components/Account/ProfileEdit/ProfilePicture";
import UsernameField from "../../components/Account/ProfileEdit/UsernameField";
import EmailField from "../../components/Account/ProfileEdit/EmailField";
import ActionButtons from "../../components/Account/ProfileEdit/ActionButtons";

const ProfileEdit = ({ userDetails = {} }) => {
  const [username, setUsername] = useState(userDetails.username || "");
  const [email, setEmail] = useState(userDetails.email || "");
  const [profilePicture, setProfilePicture] = useState(
    userDetails.profilePicture || ""
  );
  const [bannerPicture, setBannerPicture] = useState(
    userDetails.bannerPicture || ""
  );
  const [newUsername, setNewUsername] = useState(userDetails.username || "");
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [newBannerPicture, setNewBannerPicture] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // State to manage editing mode for username

  const fetchData = async () => {
    const token = localStorage.getItem("authToken");
    const loggedInEmail = localStorage.getItem("userEmail");

    try {
      const response = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const result = await response.json();
      if (result.status === 200 && result.data) {
        const user = result.data;
        setUsername(user.username);
        setEmail(user.email);
        setProfilePicture(user.profilePicture);
        setBannerPicture(user.bannerPicture);
      } else {
        console.error("Error fetching user data:", result.message);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error(`Error fetching user data: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  const handleSaveClick = async () => {
    const token = localStorage.getItem("authToken");
    const loggedInEmail = localStorage.getItem("userEmail");

    try {
      let success = false; // Track if any API request succeeds
      const promises = []; // Store promises for concurrent execution

      // Update profile picture if changed
      if (newProfilePicture) {
        const profileFormData = new FormData();
        profileFormData.append("profile_image", newProfilePicture);

        const profilePromise = fetch(
          `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/upload-profile-image`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: profileFormData,
          }
        ).then((response) => {
          if (!response.ok) throw new Error("Failed to upload profile picture");
          return response.json(); // Return the response if successful
        });

        promises.push(profilePromise);
      }

      // Update banner picture if changed
      if (newBannerPicture) {
        const bannerFormData = new FormData();
        bannerFormData.append("banner_image", newBannerPicture);

        const bannerPromise = fetch(
          `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/upload-profile-banner-image`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: bannerFormData,
          }
        ).then((response) => {
          if (!response.ok) throw new Error("Failed to upload banner picture");
          return response.json(); // Return the response if successful
        });

        promises.push(bannerPromise);
      }

      // Update username if changed and in editing mode
      if (isEditing && newUsername !== username) {
        const usernamePromise = fetch(
          `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/edit-username`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ username: newUsername }),
          }
        ).then((response) => {
          if (!response.ok) throw new Error("Failed to update username");
          return response.json(); // Return the response if successful
        });

        promises.push(usernamePromise);
      }

      // Execute all promises concurrently
      const results = await Promise.all(promises);
      success = true; // If all requests succeed, set success to true

      // Check results for each request
      if (results.length > 0) {
        toast.success("Account successfully updated!");
        setTimeout(() => {
          window.location.href = "/account"; // Redirect to Account page
        }, 1000);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6 flex flex-col items-center">
      <Helmet>
        <title>Edit Profile | Convogenius</title>
      </Helmet>
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-4xl">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Edit Profile
        </h2>
        {/* Banner Picture */}
        <BannerPicture
          bannerPicture={bannerPicture}
          setBannerPicture={setBannerPicture}
          setNewBannerPicture={setNewBannerPicture}
        />
        {/* Profile Picture */}
        <ProfilePicture
          profilePicture={profilePicture}
          setProfilePicture={setProfilePicture}
          setNewProfilePicture={setNewProfilePicture}
        />
        {/* Username */}
        <UsernameField
          newUsername={newUsername}
          setNewUsername={setNewUsername}
          isEditing={isEditing}
          setIsEditing={setIsEditing} // Pass editing state and setter
        />
        {/* Email (Read-only) */}
        <EmailField email={email} />
        {/* Action Buttons */}
        <ActionButtons handleSaveClick={handleSaveClick} />
      </div>
    </div>
  );
};

export default ProfileEdit;
