// DeleteModal.js
import React from "react";
import Modal from "react-modal";
import ClipLoader from "react-spinners/ClipLoader";

const DeleteModal = ({ isOpen, closeDeleteModal, handleDelete, isLoading }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeDeleteModal}
      className="fixed inset-0 flex justify-center items-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto my-8">
        <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
        <p className="mb-6">Are you sure you want to delete this record?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeDeleteModal}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
            disabled={isLoading}
          >
            {isLoading && (
              <ClipLoader size={20} color={"#ffffff"} className="mr-2" />
            )}
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
