import React from "react";

const MedalComponent = ({ rank }) => {
  switch (rank) {
    case 1:
      return <span className="text-yellow-500">🥇</span>; // Gold medal
    case 2:
      return <span className="text-gray-500">🥈</span>; // Silver medal
    case 3:
      return <span className="text-yellow-700">🥉</span>; // Bronze medal
    default:
      return rank;
  }
};

export default MedalComponent;
