// components/ChatHeader.jsx
import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid"; // Adjust the import according to your icon library
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Adjust the import according to your icon library
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"; // Adjust this import based on the icon you are using

const ChatHeader = () => {
  return (
    <header className="bg-white text-gray-800 py-2 px-4 flex justify-between items-center">
      <a href="/room_cht">
        <button className="flex items-center px-4 py-2 text-gray-800 hover:text-gray-500 rounded transition duration-200 text-sm">
          <ArrowLeftIcon className="h-5 w-5 mr-2 text-sm" />
          Back To Room Chat
        </button>
      </a>
      <a href="/image-ai">
        <div className="flex items-center">
          <p className="text-sm mr-2 text-gray-800 hover:text-gray-500">
            Next to AI image
          </p>
          <FontAwesomeIcon
            icon={faArrowRight}
            className="text-gray-800 hover:text-gray-200"
          />
        </div>
      </a>
    </header>
  );
};

export default ChatHeader;
