import React, { useEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/24/solid"; // Import the PencilIcon from Heroicons

const UsernameField = () => {
  const [newUsername, setNewUsername] = useState("");
  const [isEditable, setIsEditable] = useState(false); // State to track if the input is editable
  const email = localStorage.getItem("userEmail"); // Get email from localStorage
  const token = localStorage.getItem("authToken");

  // Fetch username from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://convogenius-backend-production.up.railway.app/api/v1/${email}/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch username");
        }

        const result = await response.json();
        if (result.status === 200 && result.data) {
          const user = result.data; // Get user data from result
          setNewUsername(user.Username); // Assuming the API returns { "data": { "Username": "example" } }
        } else {
          console.error("Error fetching username:", result.message);
        }
      } catch (error) {
        console.error("Error fetching username:", error);
      }
    };

    if (email && token) {
      fetchData(); // Only fetch if email and token exist in localStorage
    }
  }, [email, token]);

  // Function to handle the edit button click
  const handleEditClick = () => {
    setIsEditable(true); // Set the input to editable when the button is clicked
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-1 flex items-center">
        Username
        <button
          type="button"
          onClick={handleEditClick}
          className="ml-2 text-blue-500 hover:text-blue-700"
          title="Edit Username"
        >
          <PencilIcon className="w-5 h-5" /> {/* Adjust size as needed */}
        </button>
      </label>
      <input
        type="text"
        value={newUsername}
        onChange={(e) => setNewUsername(e.target.value)}
        readOnly={!isEditable} // Make input editable or read-only based on state
        className={`border border-gray-300 rounded-md w-full p-2 ${
          isEditable ? "bg-white" : "bg-gray-100 cursor-not-allowed"
        }`}
      />
    </div>
  );
};

export default UsernameField;
