import React from "react";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - Convogenius</title>
        <meta
          name="description"
          content="Get in touch with Convogenius. Reach us at our office in Jakarta, Indonesia, or contact us via email or phone for any inquiries."
        />
        <meta
          name="keywords"
          content="Contact Convogenius, Convogenius address, Jakarta, customer support"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.convogenius.my.id/contact" />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Contact Us - Convogenius" />
        <meta
          property="og:description"
          content="Reach out to us for any inquiries."
        />
        <meta
          property="og:image"
          content="https://www.convogenius.my.id/path/to/image.jpg"
        />
        <meta
          property="og:url"
          content="https://www.convogenius.my.id/contact"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 p-8 flex flex-col items-center">
        <h1 className="text-5xl font-bold text-gray-800 mb-6 text-center">
          Contact Us
        </h1>
        <p className="text-lg text-gray-600 mb-12 text-center max-w-3xl">
          We would love to hear from you! Feel free to reach out using the
          information below.
        </p>

        {/* Contact Information Section */}
        <div className="flex flex-col max-w-md bg-white shadow-md rounded-lg overflow-hidden p-6">
          <h2 className="text-3xl font-semibold text-gray-700 mb-4">
            Get in Touch
          </h2>
          <p className="text-md text-gray-500 mb-2">
            <strong>Address:</strong> 52, Ketapang Street, Jakarta, Indonesia
          </p>
          <p className="text-md text-gray-500 mb-2">
            <strong>Email:</strong>{" "}
            <a
              href="mailto:yusriltc@gmail.com"
              className="text-blue-500 underline"
            >
              yusriltc@gmail.com
            </a>
          </p>
          <p className="text-md text-gray-500 mb-2">
            <strong>Phone:</strong>{" "}
            <a href="tel:+6285860671780" className="text-blue-500 underline">
              +62 858-6067-1780
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
