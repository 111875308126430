import React, { useState } from "react";

const DataPermissionsPage = () => {
  const [permissions, setPermissions] = useState({
    locationTracking: false,
    dataSharing: false,
  });
  const [isLocked, setIsLocked] = useState(false); // Lock state after save
  const [showModal, setShowModal] = useState(false); // Control modal visibility

  const handleToggle = (permission) => {
    if (isLocked) return; // Prevent toggling when locked
    setPermissions((prev) => ({
      ...prev,
      [permission]: !prev[permission],
    }));
  };

  const handleSave = () => {
    setShowModal(true); // Show confirmation modal
  };

  const confirmSave = () => {
    setIsLocked(true); // Lock the permissions after saving
    console.log("Permissions saved:", permissions);
    setShowModal(false); // Close modal
  };

  const resetPermissions = () => {
    setPermissions({ locationTracking: false, dataSharing: false });
    setIsLocked(false); // Unlock permissions
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 text-gray-800">
          Data Permissions
        </h1>

        {/* Location Tracking */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Location Tracking
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Allow us to track your location to provide personalized services and
            recommendations.
          </p>
          <button
            onClick={() => handleToggle("locationTracking")}
            className={`py-2 px-4 rounded-lg shadow-lg transition duration-300 ${
              permissions.locationTracking
                ? "bg-green-500 text-white hover:bg-green-600"
                : "bg-gray-300 text-gray-700 hover:bg-gray-400"
            }`}
            disabled={isLocked}
          >
            {permissions.locationTracking ? "Disable" : "Enable"}
          </button>
        </div>

        {/* Data Sharing */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Data Sharing
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Allow us to share your data with third parties for better
            recommendations and services.
          </p>
          <button
            onClick={() => handleToggle("dataSharing")}
            className={`py-2 px-4 rounded-lg shadow-lg transition duration-300 ${
              permissions.dataSharing
                ? "bg-green-500 text-white hover:bg-green-600"
                : "bg-gray-300 text-gray-700 hover:bg-gray-400"
            }`}
            disabled={isLocked}
          >
            {permissions.dataSharing ? "Disable" : "Enable"}
          </button>
        </div>

        {/* Save Changes Button */}
        <div className="mt-8 text-right">
          <button
            className="py-2 px-6 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
            onClick={handleSave}
          >
            Save Changes
          </button>
        </div>

        {/* Reset Permissions Button */}
        {isLocked && (
          <div className="mt-4 text-right">
            <button
              className="py-2 px-6 bg-red-500 text-white rounded-lg shadow-lg hover:bg-red-600 transition duration-300"
              onClick={resetPermissions}
            >
              Reset Permissions
            </button>
          </div>
        )}

        {/* Confirmation Modal */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">
                Confirm Changes
              </h2>
              <p className="text-sm text-gray-500 mb-6">
                Are you sure you want to save these changes? You won't be able
                to modify them until you reset.
              </p>
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => setShowModal(false)}
                  className="py-2 px-4 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmSave}
                  className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataPermissionsPage;
