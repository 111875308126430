const TimelineSection = () => (
  <div className="text-center mt-12 pb-20 bg-gradient-to-b">
    <h2 className="text-4xl font-extrabold text-gray-800 mb-6">
      Convogenius Journey
    </h2>

    <p className="text-lg text-gray-700 mb-12">
      Join us as we reflect on the milestones and achievements of Convogenius,
      showcasing our journey from concept to a powerful AI platform.
    </p>

    {/* Container for Cards */}
    <div className="relative max-w-6xl mx-auto px-6 md:px-12">
      {/* Section Title for SEO */}
      <h2
        className="text-3xl font-bold text-blue-600 mb-6"
        aria-label="Timeline Events"
      >
        Timeline of Convogenius AI
      </h2>

      {/* Flex layout: Column on mobile, Row on desktop */}
      <div className="flex flex-col md:flex-row items-center gap-y-6 md:gap-x-6 overflow-x-auto md:overflow-visible scrollbar-hide snap-x snap-mandatory">
        {/* Card 1: January 2024 */}
        <div className="bg-white shadow-lg border border-blue-600 rounded-lg p-6 w-full md:w-64 h-64 text-left relative flex-shrink-0 snap-center">
          <h3
            className="text-xl font-semibold text-blue-600 mb-2"
            aria-label="January 2024 Overview"
          >
            January 2024
          </h3>
          <p
            className="text-gray-700"
            aria-label="Description for January 2024"
          >
            The idea of Convogenius AI sparked, focusing on using AI tools to
            transform product branding through text, image, and music
            generation.
          </p>
          {/* Line shown only on desktop */}
          <div className="hidden md:block absolute left-full h-1 w-6 bg-blue-500 top-1/2 transform -translate-y-1/2"></div>
        </div>

        {/* Card 2: June 2024 */}
        <div className="bg-white shadow-lg border border-blue-600 rounded-lg p-6 w-full md:w-64 h-64 text-left relative flex-shrink-0 snap-center">
          <h3
            className="text-xl font-semibold text-blue-600 mb-2"
            aria-label="June 2024 Overview"
          >
            May 2024
          </h3>
          <p className="text-gray-700" aria-label="Description for June 2024">
            Development officially began with a focused team, working towards
            bringing the platform to life.
          </p>
          {/* Line shown only on desktop */}
          <div className="hidden md:block absolute left-full h-1 w-6 bg-blue-500 top-1/2 transform -translate-y-1/2"></div>
        </div>

        {/* Card 3: August 2024 */}
        <div className="bg-white shadow-lg border border-blue-600 rounded-lg p-6 w-full md:w-64 h-64 text-left relative flex-shrink-0 snap-center">
          <h3
            className="text-xl font-semibold text-blue-600 mb-2"
            aria-label="August 2024 Overview"
          >
            August 2024
          </h3>
          <p className="text-gray-700" aria-label="Description for August 2024">
            Convogenius launched, receiving great feedback that encouraged
            continuous improvements.
          </p>
          {/* Line shown only on desktop */}
          <div className="hidden md:block absolute left-full h-1 w-6 bg-blue-500 top-1/2 transform -translate-y-1/2"></div>
        </div>

        {/* Card 4: October 2024 */}
        <div className="bg-white shadow-lg rounded-lg border border-blue-600 p-6 w-full md:w-64 h-64 text-left relative flex-shrink-0 snap-center">
          <h3
            className="text-xl font-semibold text-blue-600 mb-2"
            aria-label="October 2024 Overview"
          >
            October 2024
          </h3>
          <p
            className="text-gray-700"
            aria-label="Description for October 2024"
          >
            Convogenius reached 20,000 users, helping businesses enhance
            branding, optimize operations, and track trends.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default TimelineSection;
