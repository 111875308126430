import React from "react";
import { useNavigate } from "react-router-dom";

const ContactSupportPage = () => {
  const navigate = useNavigate();

  const handleReportProblem = () => {
    navigate("/report-problem");
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 lg:mb-0 mb-20 flex items-center justify-center">
      <div className="max-w-4xl w-full bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold mb-6 text-gray-800">
          Contact Support
        </h1>
        <p className="text-gray-500 mb-8 leading-relaxed">
          Welcome to our support center! We're here to assist you with any
          inquiries or challenges you may encounter while using our service.
        </p>
        <p className="text-gray-500 mb-8 leading-relaxed">
          If you have a question or need help, please feel free to reach out to
          us. Our team is committed to ensuring that you have a smooth
          experience.
        </p>
        <p className="text-gray-500 mb-8 leading-relaxed">
          Do you want to report a technical issue or provide feedback? Click the
          button below to access our problem reporting page.
        </p>

        <button
          onClick={handleReportProblem}
          className="py-3 px-6 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
        >
          Report a Problem
        </button>
      </div>
    </div>
  );
};

export default ContactSupportPage;
