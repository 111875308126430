const SkeletonLoader = () => {
  return (
    <div className="flex justify-start mb-2">
      <div className="max-w-xs bg-gray-200 p-3 rounded-lg shadow-md">
        <div className="flex space-x-2">
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-400"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
