// components/InfoModal.js

import React from "react";

const InfoModal = ({ show, handleClose }) => {
  const handleNavigate = () => {
    handleClose(); // Menutup modal
    window.location.href = "/questioner"; // Mengarahkan ke halaman /questioner
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-96">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">Important Information</h2>
              <button
                onClick={handleClose}
                className="text-gray-500 hover:text-gray-800"
              >
                &times;
              </button>
            </div>
            <div className="p-4">
              <p>
                Welcome to the dashboard! Please complete our questionnaire to
                better experience.
              </p>
            </div>
            <div className="flex justify-end p-4 border-t">
              <button
                onClick={handleClose}
                className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Close
              </button>
              <button
                onClick={handleNavigate}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Open Questionnaire
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoModal;
