// src/components/SkeletonBlog.js
import React from "react";

const SkeletonBlog = () => {
  return (
    <div className="container mx-auto p-4">
      {/* Card for skeleton loading */}
      <div className="border rounded-lg p-6 shadow-md bg-white">
        <div className="h-8 bg-gray-300  rounded mb-4 animate-pulse"></div>
        <div className="h-4 bg-gray-300  rounded mb-4 animate-pulse"></div>
        <div className="h-4 bg-gray-300  rounded mb-4 animate-pulse"></div>
        <div className="h-48 bg-gray-300  rounded animate-pulse"></div>
      </div>
    </div>
  );
};

export default SkeletonBlog;
