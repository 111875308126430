import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const ActionButtons = ({ handleDeleteAll }) => {
  return (
    <div className="flex justify-end mb-4">
      {" "}
      <a href="/cht-ai">
        <button className="flex items-center bg-blue-500 text-white hover:bg-blue-700 hover:text-gray-200 transition-colors px-4 py-2 rounded-2xl shadow mr-2">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          New Chat
        </button>
      </a>
      <button
        onClick={handleDeleteAll}
        className="flex items-center bg-red-500 text-white hover:bg-red-700 hover:text-gray-200 transition-colors px-4 py-2 rounded-2xl shadow"
      >
        <FontAwesomeIcon icon={faTrash} className="mr-2" />
        Delete All
      </button>
    </div>
  );
};

export default ActionButtons;
