import React from "react";
import Skeleton from "react-loading-skeleton";
import MedalComponent from "./MedalComponent";

const TableComponent = ({ loading, tableData }) => (
  <table className="min-w-full bg-white mt-8">
    <thead>
      <tr>
        <th className="px-4 py-2 border">Rank</th>
        <th className="px-4 py-2 border">Month-Year</th>
        <th className="px-4 py-2 border">Total Price</th>
      </tr>
    </thead>
    <tbody>
      {loading
        ? Array.from({ length: 5 }).map((_, index) => (
            <tr key={index}>
              <td className="px-4 py-2 border text-center">
                <Skeleton width={30} height={20} />
              </td>
              <td className="px-4 py-2 border">
                <Skeleton width={100} height={20} />
              </td>
              <td className="px-4 py-2 border">
                <Skeleton width={70} height={20} />
              </td>
            </tr>
          ))
        : tableData.map((item, index) => (
            <tr key={index}>
              <td className="px-4 py-2 border text-center">
                <MedalComponent rank={index + 1} />
              </td>
              <td className="px-4 py-2 border">{item.month_year}</td>
              <td className="px-4 py-2 border">
                {item.total_price
                  ? Number(item.total_price).toLocaleString("id-ID")
                  : "0"}
              </td>
            </tr>
          ))}
    </tbody>
  </table>
);

export default TableComponent;
